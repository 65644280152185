import React, {Component} from 'react'
import {Link} from "../../plugins/gatsby-plugin-atollon";

import {Instagram, Phone, Logo, LinkedIn, YouTube} from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout(this.isScrolling)
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({offCanvas: false})
  }

  _toggleOffCanvas = () => {
    this.setState({offCanvas: !this.state.offCanvas})
  }

  render() {

    let {offCanvas, scrolled} = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--open'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Bohm' className='header__logo' {...props}>
              <Logo color='#B94A32'/>
            </Link>
            <nav className='header__nav'>
              <ul>
                <li>
                  <Link to='/buy/' {...props}>Listings</Link>
                  <ul className='header__nav--listings'>
                    <li><Link to='/buy/' {...props}>Buy</Link></li>
                    <li><Link to='/rent/' {...props}>Short Stays</Link></li>
                    <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
                  </ul>
                </li>
                <li><Link to='/journal/' {...props}>Journal</Link></li>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
                <li><button onClick={() => this.props.setPopup(true)}>Subscribe</button></li>
                <li><Link to='https://www.youtube.com/channel/UCKiq5yr1J78NVVlS1_lUKdA'><YouTube color={'#FFFFFF'}/></Link></li>
                <li><Link to='https://www.instagram.com/bohm.house/'><Instagram color={'#FFFFFF'}/></Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas}
                    className={offCanvas ? 'header__hamburger active' : 'header__hamburger'}>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/buy/' {...props}>Buy</Link></li>
                <li><Link to='/rent/' {...props}>Short Stays</Link></li>
                <li><Link to='/journal/' {...props}>Journal</Link></li>
                <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
                <li><button onClick={() => this.props.setPopup(true)}>Subscribe</button></li>
                <li className='phone-number'><Link to='tel:+441617061816'><Phone color={'#B94A32'}/>+44 (0) 161 706 1816</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
