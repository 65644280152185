import React, {Component} from 'react'

class Logo extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="1356" height="395" viewBox="0 0 1356 395" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M166.524 336.516C223.898 336.516 257.894 313.174 257.894 271.018C257.894 228.861 223.363 206.019 161.927 206.019H55.8407V336.516H166.524ZM147.745 158.303C206.65 158.303 236.085 139.523 236.085 103.46C236.085 67.3967 207.648 50.1492 156.368 50.1492H55.8407V158.303H147.745ZM0 0.4021H166.026C244.21 0.4021 292.959 35.9306 292.959 96.8672C292.959 137.492 271.649 166.927 234.588 177.582C282.803 185.208 314.804 226.331 314.804 278.109C314.804 346.137 261.992 386.263 177.215 386.263H0V0.4021Z"
          fill={color}/>
        <path
          d="M482.825 347.669C538.665 347.669 569.134 305.513 569.134 252.736C569.134 199.96 538.665 158.303 482.825 158.303C426.984 158.303 396.516 200.424 396.516 252.736C396.516 305.049 427.483 347.669 482.825 347.669ZM340.675 252.736C340.675 173.519 396.017 111.584 483.324 111.584C570.63 111.584 624.974 173.02 624.974 252.736C624.974 332.453 569.134 394.387 483.324 394.387C397.513 394.387 340.675 330.921 340.675 252.736Z"
          fill={color}/>
        <path
          d="M655.906 0.401978H709.217V149.18C732.059 121.776 766.59 111.62 800.087 111.62C866.084 111.62 909.239 151.746 909.239 214.678V386.298H855.928V224.798C855.928 181.644 827.99 157.768 788.399 157.768C743.712 157.768 709.217 188.236 709.217 241.048V386.263H655.906V0.401978Z"
          fill={color}/>
        <path
          d="M1245.85 157.804C1211.82 157.804 1177.82 181.679 1177.82 233.458V386.298H1124.01V218.206C1124.01 176.584 1099.14 157.768 1069.67 157.768C1033.64 157.768 1000.11 181.644 1000.11 233.422V386.263H946.798V119.709H1000.11V149.679C1021.42 122.275 1053.92 111.584 1081.86 111.584C1118.42 111.584 1147.85 127.834 1164.1 158.801C1190.01 122.239 1225.04 111.584 1258.54 111.584C1317.94 111.584 1356 146.614 1356 209.047V386.227H1302.69V218.206C1302.69 176.584 1279.35 157.768 1245.82 157.768"
          fill={color}/>
        <path
          d="M445.194 59.2358C445.194 75.3787 432.116 88.4213 416.009 88.4213C399.901 88.4213 386.823 75.3431 386.823 59.2358C386.823 43.1286 399.901 30.0504 416.009 30.0504C432.116 30.0504 445.194 43.1286 445.194 59.2358Z"
          fill={color}/>
        <path
          d="M572.198 59.2358C572.198 75.3787 559.12 88.4213 543.013 88.4213C526.905 88.4213 513.827 75.3431 513.827 59.2358C513.827 43.1286 526.905 30.0504 543.013 30.0504C559.12 30.0504 572.198 43.1286 572.198 59.2358Z"
          fill={color}/>
      </svg>
    )
  }
}

const HouseLogo = (props) => (
  <svg width="38" height="50" viewBox="0 0 38 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.8935 0V8.08366H0V50H4.40028C4.77928 41.6542 10.3962 35.0301 17.2709 35.0301C24.1469 35.0301 29.7639 41.6542 30.1416 50H34.0562V28.4431H37.4248V0H21.8935ZM11.6745 27.7584H5.84305V20.055C5.84305 18.0726 7.14836 16.4667 8.75816 16.4667C10.368 16.4667 11.6733 18.0739 11.6733 20.055V27.7584H11.6745Z"
      fill={props.color || "#21242F"}/>
  </svg>
)
const Icon = (props) => (
  <svg width="22" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.26843 0V3.22384H0.537109V19.9405H2.29198C2.44313 16.6121 4.68322 13.9703 7.42491 13.9703C10.1671 13.9703 12.4072 16.6121 12.5578 19.9405H14.119V11.3434H15.4625V0H9.26843ZM5.19303 11.0703H2.86737V7.99812C2.86737 7.20753 3.38794 6.56706 4.02994 6.56706C4.67194 6.56706 5.19251 7.20804 5.19251 7.99812V11.0703H5.19303Z"
      fill={props.color || 'white'}/>
  </svg>
)
const DownArrow = (props) => (
  <svg width="76" height="78" viewBox="0 0 76 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.1391 76.5749L75.4792 42.2826C76.5664 41.2573 75.8381 39.4346 74.3393 39.4346L37.9164 63.0953L1.65892 39.4346C0.161827 39.4346 -0.568253 41.2573 0.518951 42.2826L36.8591 76.5749C37.4977 77.1778 38.4987 77.1778 39.1373 76.5749L39.1391 76.5749Z"
      fill={props.color}></path>
    <path
      d="M37.9164 -2.07624e-07C41.4137 -5.47513e-08 42.6663 2.82526 42.6663 6.30952L39.4997 66.25L36.3331 66.25L33.1665 6.30952C33.1665 2.82526 34.419 -3.60497e-07 37.9164 -2.07624e-07Z"
      fill={props.color}></path>
  </svg>
)

class Arrow extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-1363.000000, -162.000000)">
            <g transform="translate(1362.894118, 162.000000)">
              <g transform="translate(6.794118, 7.000000) scale(-1, 1) translate(-6.794118, -7.000000)">
                <g
                  transform="translate(6.656155, 7.000000) rotate(90.000000) translate(-6.656155, -7.000000) translate(0.000000, 0.411765)">
                  <polygon fill={color} fill-rule="nonzero"
                           points="6.03849121 0.0185693147 7.27324876 0.0185693147 7.27324876 12.3677749 6.03849121 12.3677749"></polygon>
                  <polygon fill={color} fill-rule="nonzero"
                           points="0.980057732 5.62839054 7.52903529 12.1773681 6.65562547 13.0507779 0.106647908 6.50180036"></polygon>
                  <polygon fill={color} fill-rule="nonzero"
                           points="12.3322256 5.62839054 13.2056354 6.50180036 6.65665785 13.0507779 5.78324803 12.1773681"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

const LongArrow = (props) => (
  <svg width="33" height="10" viewBox="0 0 33 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M27.3547 8.86353L30.8084 5.44729H0.797363V4.72731H30.8084L27.3547 1.31107L27.8696 0.801758L32.2022 5.08734L27.8696 9.37292L27.3547 8.86353Z"
          fill={props.color}/>
  </svg>
)

class Play extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.786865 0.0180664L6.02367 2.99679L11.2131 6.02367L6.02367 9.00303L0.786865 12.0299V0.0180664Z"
              fill={color || 'white'}/>
      </svg>
    )
  }
}

class YouTube extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="23px" height="16px" viewBox="0 0 23 16" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1355.000000, -67.000000)" fill={color}>
            <g id="/i/youtube" transform="translate(1355.000000, 67.000000)">
              <path
                d="M22.6349206,11.8191726 L22.6349206,3.84398525 C22.6349206,3.84398525 22.6349206,-2.61778903e-15 18.7342296,-2.61778903e-15 L3.89936015,-2.61778903e-15 C3.89936015,-2.61778903e-15 0,-2.61778903e-15 0,3.84398525 L0,11.8191726 C0,11.8191726 0,15.6631579 3.89936015,15.6631579 L18.7342296,15.6631579 C18.7342296,15.6631579 22.6349206,15.6631579 22.6349206,11.8191726 M15.7118928,7.84272664 L8.30177769,12.1313079 L8.30177769,3.55283386 L15.7118928,7.84272664"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Instagram extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1313.000000, -66.000000)" fill={color}>
            <g id="/i/instagram" transform="translate(1313.000000, 66.000000)">
              <path
                d="M5.27741655,-3.55271368e-15 L13.008104,-3.55271368e-15 C15.9111902,-3.55271368e-15 18.2851777,2.34461691 18.2851777,5.20890427 L18.2851777,12.918678 C18.2851777,15.7836511 15.9111902,18.1275823 13.008104,18.1275823 L5.27741655,18.1275823 C2.37433032,18.1275823 0,15.7836511 0,12.918678 L0,5.20890427 C0,2.34461691 2.37430175,-3.55271368e-15 5.27741655,-3.55271368e-15 Z M9.09333313,4.16956335 C11.9144218,4.16956335 14.2036974,6.45883902 14.2036974,9.27992766 C14.2036974,12.1013591 11.9144218,14.390292 9.09333313,14.390292 C6.2715588,14.390292 3.98262598,12.1013591 3.98262598,9.27992766 C3.98262598,6.45883902 6.2715588,4.16956335 9.09333313,4.16956335 Z M9.09333313,5.89562698 C10.9614783,5.89562698 12.4776338,7.41146821 12.4776338,9.27992766 C12.4776338,11.1483871 10.9614497,12.6645712 9.09333313,12.6645712 C7.22453083,12.6645712 5.70868961,11.1484157 5.70868961,9.27992766 C5.70868961,7.41146821 7.22453083,5.89562698 9.09333313,5.89562698 Z M14.0373309,3.46126985 C14.4956032,3.46126985 14.8671351,3.8328018 14.8671351,4.29073122 C14.8671351,4.74900349 14.4956032,5.12053544 14.0373309,5.12053544 C13.5794015,5.12053544 13.2078695,4.74900349 13.2078695,4.29073122 C13.2078695,3.8328018 13.5794015,3.46126985 14.0373309,3.46126985 L14.0373309,3.46126985 Z M5.9091123,1.48075654 L12.3770653,1.48075654 C14.8057083,1.48075654 16.7919643,3.45721283 16.7919643,5.87239909 L16.7919643,12.3727226 C16.7919643,14.7878517 14.8057369,16.7639937 12.3770653,16.7639937 L5.9091123,16.7639937 C3.48046929,16.7639937 1.49389901,14.7878517 1.49389901,12.372694 L1.49389901,5.87234195 C1.49389901,3.45721283 3.48046929,1.48075654 5.9091123,1.48075654 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class LinkedIn extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.539062 5.26367H3.60191V15.9381H0.539062V5.26367Z"
              fill="#B94A32"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M1.99254 3.9283H1.97037C0.861644 3.9283 0.142822 3.1123 0.142822 2.07897C0.142822 1.02451 0.882894 0.224609 2.01379 0.224609C3.14376 0.224609 3.83856 1.0225 3.86074 2.07595C3.86074 3.10928 3.14376 3.9283 1.99254 3.9283Z"
              fill="#B94A32"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.8572 15.9387H12.3841V10.4138C12.3841 8.96797 11.8409 7.98193 10.6462 7.98193C9.73243 7.98193 9.22427 8.64701 8.98774 9.28995C8.89904 9.51935 8.9129 9.84032 8.9129 10.1623V15.9387H5.47217C5.47217 15.9387 5.51652 6.15272 5.47217 5.26327H8.9129V6.93854C9.11617 6.20605 10.2156 5.16064 11.9702 5.16064C14.147 5.16064 15.8572 6.69706 15.8572 10.0023V15.9387Z"
              fill="#B94A32"/>
      </svg>
    )
  }
}

class Plus extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-429.000000, -1448.000000)" fill={color}>
            <g transform="translate(429.000000, 1448.000000)">
              <polygon
                points="15.2283871 27.3135484 15.2283871 14.9574194 27.8554839 14.9574194 27.8554839 12.4103226 15.2283871 12.4103226 15.2283871 0 12.6812903 0 12.6812903 12.4103226 5.13424428e-14 12.4103226 5.13424428e-14 14.9574194 12.6812903 14.9574194 12.6812903 27.3135484"></polygon>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Sound extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5.11198H2V12.78H0V5.11198Z" fill={color}/>
        <path d="M5 2.55597H7V12.78H5V2.55597Z" fill={color}/>
        <path d="M10 0H12V12.78H10V0Z" fill={color}/>
      </svg>
    )
  }
}

class ScrollArrow extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="12" height="52" viewBox="0 0 12 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.669193 44.8885L5.15714 49.4258L5.15714 0H6.10298L6.10298 49.4258L10.5909 44.8885L11.26 45.5649L5.63 51.2568L0 45.5649L0.669193 44.8885Z"
              fill={color}/>
      </svg>
    )
  }
}

class RightArrow extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-1363.000000, -162.000000)">
            <g transform="translate(1362.894118, 162.000000)">
              <g transform="translate(6.794118, 7.000000) scale(-1, 1) translate(-6.794118, -7.000000)">
                <g
                  transform="translate(6.656155, 7.000000) rotate(90.000000) translate(-6.656155, -7.000000) translate(0.000000, 0.411765)">
                  <polygon fill={color} fill-rule="nonzero"
                           points="6.03849121 0.0185693147 7.27324876 0.0185693147 7.27324876 12.3677749 6.03849121 12.3677749"></polygon>
                  <polygon fill={color} fill-rule="nonzero"
                           points="0.980057732 5.62839054 7.52903529 12.1773681 6.65562547 13.0507779 0.106647908 6.50180036"></polygon>
                  <polygon fill={color} fill-rule="nonzero"
                           points="12.3322256 5.62839054 13.2056354 6.50180036 6.65665785 13.0507779 5.78324803 12.1773681"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Search extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15.4947 7.66592C15.4947 9.32415 14.9115 10.9627 13.8475 12.2567L18.4781 16.6865L17.164 17.9608L12.5546 13.4908C11.199 14.5217 9.51023 15.0882 7.82062 15.0882C3.60806 15.0882 0.166931 11.7511 0.166931 7.66592C0.166931 3.58073 3.58678 0.243652 7.81978 0.243652C12.0536 0.243652 15.4947 3.58073 15.4947 7.66592ZM2.02276 7.70613C2.02276 10.8007 4.62967 13.3288 7.82074 13.3288C11.0118 13.3288 13.6187 10.8007 13.6187 7.70613C13.6187 4.61154 11.0118 2.08345 7.82074 2.08345C4.62967 2.08345 2.02276 4.61154 2.02276 7.70613Z"
              fill={color}></path>
      </svg>
    )
  }
}

class Close extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-1360.000000, -67.000000)" fill={color}>
            <g transform="translate(1357.500000, 64.970563)">
              <g transform="translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000) ">
                <polygon
                  points="13.0529032 23.4116129 13.0529032 12.8206452 23.876129 12.8206452 23.876129 10.6374194 13.0529032 10.6374194 13.0529032 0 10.8696774 0 10.8696774 10.6374194 4.40078082e-14 10.6374194 4.40078082e-14 12.8206452 10.8696774 12.8206452 10.8696774 23.4116129"></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Phone extends Component {
  render() {
    let {color} = this.props
    return (
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color} fillRule="nonzero">
            <path
              d="M0.32686762,6.15611621 C0.32686762,6.15611621 -0.206548317,4.47547589 0.089454016,2.85566492 C0.32686762,1.29524448 1.15628589,0.695064327 1.39294962,0.515413945 C1.63036323,0.395202061 1.98572389,0.155553647 1.98572389,0.155553647 C1.98572389,0.155553647 2.4004429,-0.264416589 2.8745005,0.275765531 C3.3485581,0.755825724 5.54088951,3.03586684 5.54088951,3.03586684 C5.54088951,3.03586684 5.95560852,3.51592703 5.6002439,3.99600721 C5.24488323,4.41597744 3.99997636,5.67664752 3.99997636,5.67664752 C4.53339229,7.71714813 8.14795571,11.3772999 10.1627449,11.91745 C10.1627449,11.91745 11.4068822,10.7168899 11.8809398,10.3570296 C12.2956588,9.99716927 12.7697164,10.4171355 12.7697164,10.4171355 C12.7697164,10.4171355 15.0213075,12.5777361 15.5547037,13.0579362 C16.0287613,13.5981063 15.6140581,13.9579666 15.6140581,13.9579666 C15.6140581,13.9579666 15.4359949,14.3779368 15.2586974,14.5582467 C15.1399886,14.7986664 14.4886375,15.6385869 12.9477085,15.9383373 C11.4067993,16.1787571 9.68852551,15.6385869 9.68852551,15.6385869 C6.13255082,14.1983864 1.74737492,9.81763479 0.325182381,6.15668373 L0.32686762,6.15611621 Z"></path>
          </g>
        </g>
      </svg>
    )
  }
}

const Projects = () => (
  <svg width="95" height="97" viewBox="0 0 95 97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="rect" fill-rule="evenodd" clip-rule="evenodd"
          d="M11.45 17.6392H47.0855H51.4982H87.1337V96.329H81.9592C81.5135 86.5149 74.9083 78.7252 66.824 78.7252C58.7383 78.7252 52.1331 86.5149 51.6889 96.329H51.4982H47.0855H46.3237C45.878 86.5149 39.2728 78.7252 31.1885 78.7252C23.1027 78.7252 16.4975 86.5149 16.0533 96.329H11.45V70.9794V17.6392Z"
          fill="#FFFFFF"></path>
    <path
      d="M27.475 45.5614H19.8725L19.8742 35.5186C19.8742 32.9359 21.5759 30.8406 23.6746 30.8406C25.7733 30.8406 27.475 32.9342 27.475 35.5186V45.5614Z"
      fill="#e9e5db"></path>
    <path
      d="M45.0557 45.5614H37.4532L37.4549 35.5186C37.4549 32.9359 39.1566 30.8406 41.2553 30.8406C43.3539 30.8406 45.0557 32.9342 45.0557 35.5186V45.5614Z"
      fill="#e9e5db"></path>
    <path
      d="M62.6365 45.5614H55.034L55.0357 35.5186C55.0357 32.9359 56.7374 30.8406 58.8361 30.8406C60.9348 30.8406 62.6365 32.9342 62.6365 35.5186V45.5614Z"
      fill="#e9e5db"></path>
    <path
      d="M80.2172 45.5614H72.6147L72.6164 35.5186C72.6164 32.9359 74.3181 30.8406 76.4168 30.8406C78.5154 30.8406 80.2172 32.9342 80.2172 35.5186V45.5614Z"
      fill="#e9e5db"></path>
    <path
      d="M27.475 67.4096H19.8725L19.8742 57.3668C19.8742 54.784 21.5759 52.6887 23.6746 52.6887C25.7733 52.6887 27.475 54.7824 27.475 57.3668V67.4096Z"
      fill="#e9e5db"></path>
    <path
      d="M45.0557 67.4096H37.4532L37.4549 57.3668C37.4549 54.784 39.1566 52.6887 41.2553 52.6887C43.3539 52.6887 45.0557 54.7824 45.0557 57.3668V67.4096Z"
      fill="#e9e5db"></path>
    <path
      d="M62.6365 67.4096H55.034L55.0357 57.3668C55.0357 54.784 56.7374 52.6887 58.8361 52.6887C60.9348 52.6887 62.6365 54.7824 62.6365 57.3668V67.4096Z"
      fill="#e9e5db"></path>
    <path
      d="M80.2172 67.4096H72.6147L72.6164 57.3668C72.6164 54.784 74.3181 52.6887 76.4168 52.6887C78.5154 52.6887 80.2172 54.7824 80.2172 57.3668V67.4096Z"
      fill="#e9e5db"></path>
    <rect class="rect" x="0.866455" width="42.7638" height="28.5092" fill="#FFFFFF"></rect>
    <path
      d="M16.7841 21.8481H9.1816L9.18327 11.8052C9.18327 9.22252 10.885 7.1272 12.9837 7.1272C15.0823 7.1272 16.7841 9.22085 16.7841 11.8052V21.8481Z"
      fill="#e9e5db"></path>
    <path
      d="M34.3646 21.8481H26.7622L26.7638 11.8052C26.7638 9.22252 28.4656 7.1272 30.5642 7.1272C32.6629 7.1272 34.3646 9.22085 34.3646 11.8052V21.8481Z"
      fill="#e9e5db"></path>
  </svg>
)

export {
  Phone,
  Logo,
  Icon,
  Arrow,
  LongArrow,
  Play,
  Sound,
  ScrollArrow,
  Search,
  HouseLogo,
  Projects,
  DownArrow,
  Plus,
  Instagram,
  LinkedIn,
  YouTube,
  RightArrow,
  Close
}