import React from 'react'
import {AtollonProvider, Link} from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query"
import {CookieNotice} from "gatsby-cookie-notice";
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'
import Popup from '../components/popup'

const Layout = (props) => {

    const globalData = layoutQuery()

    const [popup, setPopup] = React.useState(false)

    return (
        <AtollonProvider {...props} globalData={globalData}>
            <Header popup={popup} setPopup={setPopup} />
            <main>
                { props.children }
            </main>
            <Footer />
            <Popup popup={popup} setPopup={setPopup} />
            <CookieNotice
                personalizeButtonClasses={"btn--manage"}
                personalizeButtonText={"Manage preferences"}
                acceptButtonText={"Accept all"}
                declineButtonText={"Reject all"}
                cookies={[
                        {name: 'necessary', editable: false, default: true, title: 'Essential', text: 'Essential cookies are necessary for the proper functioning of the site. The site cannot function properly without them.' },
                        {name: 'gatsby-gdpr-google-tagmanager', editable: true, default: true, title: 'Google Tag Manager', text: 'Google Tag Manager is a statistical tool of Google allowing to measure the audience of the website.' }
                    ]}>
                <div className='bg-cookie__content'>
                    <h4>This websites uses cookies.</h4>
                    <p>We use cookies to make the site work better, but also to see how you interact with it. We will only use cookies if you allow us to do so by clicking by clicking on "Accept all". You can also choose which cookie you want to allow.</p>
                </div>
            </CookieNotice>
        </AtollonProvider>
    )
}

export default Layout