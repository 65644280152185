import React, { useState } from 'react'
import { Close } from './icons'
import Newsletter from "./structured-text/blocks/newsletter";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}
function getCookie(name) {
  let nameEQ = name + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return false
}

const Popup = ({ title, content, thanksContent, subscribeTitle, image, popup, setPopup }) => {
  const [initial, setInitial] = useState(true)

  React.useEffect(() => {
    if (getCookie('newsletter-popup')) return

    setTimeout(() => {
      setPopup(true)
      setCookie('newsletter-popup', true, 365)
    }, 6000)
  }, [])

  const closePopupHandle = () => {
    setPopup(false)
  }

  return (
    <div className={ `popup popup--small ${ popup ? 'active' : '' }` }>
      <div className='popup__close-bg' onClick={ closePopupHandle }></div>
      <div className='popup__wrapper'>
        <button className='popup__close' onClick={ closePopupHandle }>
          <Close color='#000000'/>
        </button>
        <div className='popup__content'>
          <h4>Like what you see?</h4>
          <p>Subscribe to our newsletter for property insights and updates.</p>
          <Newsletter />
        </div> 
      </div>
    </div>
  )
}

export default Popup